.sideBar {
    background-color: var(--secodary-bg-w-color);
    box-shadow: 0px 0px 5px 1px gray;
    border-radius: 3px 3px 3px 3px;
    padding: 1rem;
    display: block;
    align-items: center;
    justify-content: space-between;
    min-height: 200px;
}

.sideBar .button{
    color: #AD0039;
    text-align: left;
    font-size: 14px;
    padding: 1rem 1rem 1rem 1rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center
}

.sideBar .button span{
    margin-left: .5rem;
    
}

.sideBar .button:hover{
    border-left: .4rem solid #AD0039;
    background-color: #E4E1E1;
    font-weight: bold;
}

.sideBar .button.active{
    border-left: .4rem solid #AD0039;
    background-color: #E4E1E1;
    font-weight: bold;
}

.container {
    background-color: var(--secodary-bg-w-color);
    box-shadow: 0px 0px 5px 1px gray;
    border-radius: 1px 1px 1px 1px;

    display: block;
    align-items: center;
    justify-content: space-evenly;
    padding: 20px 30px 20px 30px;
}

.container h2 {
    color: #AD0039;
    font-weight: bold;
    text-align: left;
    font-size: 26px;
}

.primary {
    font-size: 10px;
    border-radius: 10px;
    padding: 7px;
    background-color: #AD0039;
    color: white;
    font-weight: bold;
    border: 1px solid transparent;
}

.newPrimary {
    font-size: 14px;
    border-radius: 10px;
    padding: 7px;
    background-color: #AD0039;
    color: white;
    font-weight: bold;
    border: 1px solid transparent;
}


.secondary {
    font-size: 10px;
    border-radius: 10px;
    padding: 7px;
    background-color: white;
    color: #AD0039;
    font-weight: bold;
    border: 1px solid #AD0039;
}

.label {
    text-align: left;
    font-size: 12px;
    font-family: FiraSans-Regular!important;
}

.input {
    padding: 7px;
    font-size: 12px;
    border-radius: 0;
    border: 0px solid transparent;
    border-bottom: 1px solid silver;
    box-shadow: 0;
    padding-left: 0;
    font-family: FiraSans-Light!important;
}

.inputFloating {
    font-family: FiraSans-Light!important;
    font-size: 12px;
    border: 0px solid transparent;
    border-bottom: 1px solid silver;
    border-radius: 0px;
    height: calc(3rem + 2px)!important;
}

.inputSelect {
    padding: 7px;
    font-size: 12px;
    border-radius: 0;
    border: 0px solid transparent;
    border-bottom: 1px solid silver;
    box-shadow: 0;
    font-family: FiraSans-Light!important;
}

.smallInput {
    padding: 5px;
    font-size: 10px;
    border-radius: 0;
    border: 0px solid transparent;
    border-bottom: 1px solid silver;
    box-shadow: 0;
    padding-left: 0;
    font-family: FiraSans-Light!important;
}

.item {
    font-size: 12px;
}

.sortItem {
    background: #F7F7F7!important;
    font-weight: 500;
    color: black;
}

.iconActive {
    color: #AD0039;
    cursor: pointer;
}

.iconInactive {
    color: gray;
    cursor: pointer;
}

.iconInactive:focus {
    color: darkslategrey;
}

.table thead {
    font-size: 12px;
    text-align: center;
}

.table tbody {
    font-size: 10px;
    text-align: center;    
}

.complexityLevel {
    font-size: 10px;
    font-family: FiraSans-Light!important;
    padding: 5px 7px 5px 7px;
}

.next {
    background: transparent;
    color: #AD0039;
    border: 0px solid transparent;
    cursor: pointer;
    font-size: 12px;
}

.next:hover {
    font-weight: bold;
}

.optionItem {
    font-size: 12px;
}

.option {
    border-radius: 50%;
    font-size: 10px;
    width: 20px;
    height: 20px;
    padding: 0px;
    margin-left: 10px;
    background: transparent;
    color: rgb(88, 88, 88);
    border: 0px solid transparent;
}

.option:focus, .option:active {
    background: transparent;
    color: rgb(88, 88, 88);
    border: 0px solid transparent;
}

.option::after {
    content: none;
}

.newPrimary {
    font-size: 14px;
    border-radius: 10px;
    padding: 7px;
    background-color: #AD0039;
    color: white;
    font-weight: bold;
    border: 1px solid transparent;
}